export const ENVIRONMENT = {
    // baseUrl: "https://vault-svc1.dev.ziroh.com/websiteserver/api/v1",
    baseUrl:"https://websiteserver-svc.vault.ziroh.com/api/v1",


    zunuDownloadUrls: {
        builds: "/builds",
        windows: "https://storage.googleapis.com/zunu-releases/win/Zunu-X64-v1.1.5.msi",
        mac: "https://apps.apple.com/in/app/zunu/id1672198613?mt=12",
        mac_intel: "https://storage.googleapis.com/zunu-releases/mac/intel/Zunu-intel-v1.0.23.pkg",
        android: "https://play.google.com/store/apps/developer?id=Ziroh+Labs+Inc.&hl",
        indus: "https://www.indusappstore.com/#scanToDownloadApp",
        ios: "https://apps.apple.com/in/developer/ziroh-labs-inc/id1665684284",
        linux_18: "https://storage.googleapis.com/zunu-releases/linux/Ubuntu18/Zunu-amd64-18-v1.1.0.deb",
        linux_22: "https://storage.googleapis.com/zunu-releases/linux/Ubuntu22/Zunu-amd64-22-v1.1.0.deb",
        winows_portable: "https://storage.googleapis.com/zunu-releases/portable/windows/Zunu-Portable-WinX64-v1.1.5.zip",
        mailPlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumail&pcampaignid=web_share",
        mailAppStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumail&pcampaignid=web_share",
        drivePlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunudrive&pcampaignid=web_share",
        driveAppStore: "https://apps.apple.com/in/app/zunu-drive/id1665684282",
        messagePlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumessage&pcampaignid=web_share",
        cameraPlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunucamera&pcampaignid=web_share",
        cameraAppStore: "https://apps.apple.com/in/app/zunu-camera/id6468880940",
    }
};